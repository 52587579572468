/* .ant-btn {
  color: #f86c6b !important;
  background-color: transparent !important;
  
} */

/* .ant-btn:hover {
  color: white !important;
  background-color: #f86c6b !important
} */

.ant-btn-primary {
  color: #00ae41 !important;
  background-color: transparent !important;
  border-color: #59cc50 !important;
}

.ant-btn-primary:hover {
  color: white !important;
  background-color: #00ae41 !important;
  border-color: #00ae41 !important;
}

.btn-back {
  color: #00ae41;
  background-color: transparent;
  border-color: #59cc50;
}

.Btn-Submit {
  margin-left: 10px;
}

.btn-login {
  width: 100%;
  height: 31.5px;
  border-radius: 6px;
  border: solid 0.5px transparent;
  background-color: #295caa;
  color: white;
}

.btn-signUp {
  width: 100%;
  height: 31.5px;
  border-radius: 6px;
  border: solid 0.5px #707070;
  background-color: white;
  color: #43425d;
}

.btn-back:hover {
  color: #00ae41;
  background-color: #c8ced3;
  border-color: #59cc50;
}

/* .btn-success {
  color: white !important;
  background-color: #59cc50 !important;
  border-color: #59cc50 !important  ;
}

.btn-success:hover {
  color: white !important;
  background-color: #59cc50 !important;
  border-color: #59cc50 !important  ;
} */
.btn-hapus{
  color:#636464;
  background-color: transparent;
  border-color:#636464;
}
.btn-hapus:hover {
  color:#f86c6b !important;
  background-color: transparent;
} 


.btn-secondary{
  color:#636464;
  background-color: transparent;
  border-color:#636464;
}

.btn-secondary:hover {
  color: #4CAF50;
  background-color: transparent;
} 
.btn-danger:hover {
  color: #f86c6b;
  background-color: transparent;
  /* border-color: #f86c6b; */
}

/* .btn-warning {
  color: #ffc107;
  background-color: transparent;
  border-color: #ffc107;
} */
.btn-primary-sm {

  color: #1a89cb;
  background-color: transparent;
  border-color: #1a89cb;
}


.color-Text {
  color:white;
}

.btn-primary-sm:hover {
  color: white;

  background-color: #1a89cb;
  border-color: #1a89cb;
}

.btn-login-sm {
  color: White;
  background-color: #ffcb0f;
  border-color: #ffcb0f;
}

.btn-login-sm:hover {
  color: white;
  background-color: #d3a80c;
  border-color: #d3a80c;
}

.btn-formOnline {
  color: #43425d;
  background-color: transparent;
  border-color: #d9d9d9;
}

.btn-formOnline:hover {
  color: white;
  background-color: #20a8d8;
  border-color: #20a8d8;
}
.listHeader{
  color: #29a7f2;

}
